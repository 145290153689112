import { Controller } from "stimulus"

export default class extends Controller {
  onClick() {
    const dataSet = this.element.dataset;
    if (window.ga) {
      window.ga('send', 'event', 'category-card-click', dataSet['type'], new URL(dataSet['url']).pathname, dataSet['index'])
    }
  }
}
