import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.MOBILE_VIEW_BREAKPOINT = 480
    this._insertSidebarAdsForMobileView()
  }

  _insertSidebarAdsForMobileView() {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if(width > this.MOBILE_VIEW_BREAKPOINT) {
      document.querySelectorAll('.category_sidebar_ad_mobile').forEach((v) => v.remove())
      document.querySelectorAll('.sticky-ad-mobile').forEach((v) => v.remove())
    } else {
      this._insertMobileAd('.category_sidebar_ad_mobile', this._adPlacementListCount())
      this._insertMobileAd('.sticky-ad-mobile', this._numberOfListElements() - 1)
    }
  }

 _insertMobileAd(selector, offset) {
    var templateAd = document.querySelector(selector)
    if(templateAd) {
      templateAd.style.display = 'block'
      const el = this._elementToInsertAfter(offset)
      if(el) {
        el.parentNode.insertBefore(templateAd, el.nextElementSibling)
      }
    }
 }
  
  _elementToInsertAfter(ad_placement_list_count) {
    const els = this._listElements()
    if(Tutsplus.adsEnabled) {
      return els[ad_placement_list_count]
    }
  }

  _adPlacementListCount() {
    return Math.floor((this._listElements().length) / 2) - 1
  }

  _numberOfListElements() {
    return this._listElements().length
  }

  _listElements() {
    return this.element.querySelectorAll('li')
  }
}
