import { Controller } from "stimulus"
import Tether from 'tether'
import { onDesktop } from 'utils/screen_resolution_util'

export default class extends Controller {
  connect() {
    const list = document.querySelector('.global-nav__header-second-level-list')
    const ad = this.element.querySelector('.sticky-ad')
    const adTraget = this.element.querySelector('.sticky-ad-target')
    let tether = {}

    if(onDesktop() && ad) {
      const postSidebar = adTraget
      const post        = this.element

      tether = new Tether({
        element: ad,
        target:  postSidebar,
        attachment: 'top left',
        targetAttachment: 'top left',
        constraints: [
          {to: 'window', pin: ['top']},
          {to: post, pin: ['bottom']},
          {to: postSidebar, pin: ['top']}
        ]
      })
    }

    let navOffset = 155

    window.addEventListener('nav-open', () => {
      navOffset = 155
    })

    window.addEventListener('nav-close', () => {
      navOffset = 52
    })

    Tether.modules.push({
      position: ({elementPos, targetPos, top, left, targetAttachment}) => {
        const nav_bottom = list.getBoundingClientRect().top
        const offset = 10
        if (targetPos.y <= nav_bottom + offset) {
          top = top + navOffset + offset
        }
        return {elementPos, targetPos, top, left, targetAttachment}
      }
    })
  }
}
